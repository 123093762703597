import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProviderComponent } from "@thisgorilla/primate-ds";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "./index.css";

import App from "./App";
import store from "./redux";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProviderComponent>
    <ToastContainer />
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProviderComponent>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
