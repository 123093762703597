import { ReactNode } from "react";
import { AuthCommonStyle } from "./style";

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <AuthCommonStyle.PageContainer>
      <AuthCommonStyle.AuthContainer>
        <AuthCommonStyle.AuthWrapper>{children}</AuthCommonStyle.AuthWrapper>
      </AuthCommonStyle.AuthContainer>
    </AuthCommonStyle.PageContainer>
  );
};

export default AuthLayout;
