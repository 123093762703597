import styled from "styled-components";

import { LeftPanel } from "./LeftPanel";
import { RightPanel } from "./RightPanel";
import { CenterPanel } from "./CenterPanel";

export const Home = () => {
  return (
    <Container>
      <LeftPanel />
      <CenterPanel />
      <RightPanel />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing["spacing-9"].value}px;
`;
