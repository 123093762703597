import { useState } from "react";
import { Formik, FormikValues } from "formik";
import { Components, Icons as DSIcons, tokens } from "@thisgorilla/primate-ds";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import Input from "../../Input";
import { AuthProps } from "../Auth";
import { Icons } from "../../icons";
import AuthLayout from "../AuthLayout";
import { AuthCommonStyle } from "../style";
import { API_CALLS } from "../../../api_calls";
import { loginValidationSchema } from "./utils";
import { ROUTES } from "../../../utills/routes";
import { API_PATHS } from "../../../utills/api_paths";
import { useAppSelector } from "../../../redux";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectURL = searchParams.get("redirectURL") || ROUTES.home;
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const { desktop_typography, light } = tokens;
  const [submitClicked, setSubmitClicked] = useState(false);
  const { isAPICallInProgress } = useAppSelector((state) => state.auth);

  const onSubmit = (values: FormikValues) => {
    API_CALLS.AUTH.login(
      values as AuthProps.loginFormValues,
      redirectURL,
      navigate
    );
  };
  return (
    <AuthLayout>
      <AuthCommonStyle.Wrapper>
        <Components.Text
          style={{
            tokenStyle: desktop_typography.Heading.L,
          }}
          text="Sign in"
          color={light.text["text-secondary"].value}
        />

        <AuthCommonStyle.Container>
          <AuthCommonStyle.SocialButton
            onClick={() =>
              window.open(`${API_PATHS.socialAuth}/google`, "_self")
            }
          >
            <Icons.Google />
            <Components.Text
              style={{
                tokenStyle: desktop_typography.Body.L,
              }}
              text="Continue with Google"
              color={light.text["text-primary"].value}
            />
          </AuthCommonStyle.SocialButton>
          <AuthCommonStyle.SocialButton
            onClick={() =>
              window.open(`${API_PATHS.socialAuth}/apple`, "_self")
            }
          >
            <Icons.Apple />
            <Components.Text
              style={{
                tokenStyle: desktop_typography.Body.L,
              }}
              text="Continue with Apple"
              color={light.text["text-primary"].value}
            />
          </AuthCommonStyle.SocialButton>
        </AuthCommonStyle.Container>
      </AuthCommonStyle.Wrapper>
      <AuthCommonStyle.InfoContainer>
        <AuthCommonStyle.Divider />
        <Components.Text
          style={{
            tokenStyle: desktop_typography.Body.M,
          }}
          text="or Sign in with Email"
          color={light.text["text-tertiary"].value}
          fontWeight="font-weight-light"
        />
        <AuthCommonStyle.Divider />
      </AuthCommonStyle.InfoContainer>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={loginValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <AuthCommonStyle.Wrapper>
              <AuthCommonStyle.GroupContainer>
                <AuthCommonStyle.GroupContainer>
                  <Input
                    fieldName="email"
                    placeholder="Email"
                    isRequired
                    idPrefix="login"
                  />
                  <Input
                    fieldName="password"
                    placeholder="Password"
                    isRequired
                    idPrefix="login"
                    type={isPasswordShow ? "text" : "password"}
                    Icon={isPasswordShow ? DSIcons.EyeOff : DSIcons.Eye}
                    iconClickhandler={() => setIsPasswordShow(!isPasswordShow)}
                    onKeyDown={(e) => {
                      const isEnterKey = e.key === "Enter";
                      if (isEnterKey) formik.submitForm();
                    }}
                  />
                </AuthCommonStyle.GroupContainer>

                <NavLink
                  to={ROUTES.forgotPassword}
                  style={{ textDecoration: "none", width: "fit-content" }}
                >
                  <Components.Text
                    style={{ tokenStyle: desktop_typography.Body.M }}
                    text="Forgot password?"
                    color={light.text["text-emphasize-brand"].value}
                  />
                </NavLink>
              </AuthCommonStyle.GroupContainer>
              <AuthCommonStyle.GroupContainer>
                <Components.Button
                  color="Primary"
                  kind="Primary"
                  label="Sign in"
                  size="Large"
                  width="fill"
                  onClick={() => {
                    setSubmitClicked(true);
                    formik.isValid && formik.submitForm();
                  }}
                  disabled={
                    (submitClicked && !formik.isValid) ||
                    isAPICallInProgress ||
                    Object.keys(formik.errors).length > 0
                  }
                />
                <div style={{ textAlign: "center" }}>
                  <Components.Text
                    style={{ tokenStyle: desktop_typography.Body.M }}
                    text="Not registered? "
                    color={light.text["text-primary"].value}
                    fontWeight="font-weight-light"
                  />
                  <NavLink to={ROUTES.register}>
                    <Components.Text
                      style={{ tokenStyle: desktop_typography.Body.M }}
                      text="Sign up"
                      color={light.text["text-emphasize-brand"].value}
                      isunderlined
                      fontWeight="font-weight-semibold"
                    />
                  </NavLink>
                </div>
              </AuthCommonStyle.GroupContainer>
            </AuthCommonStyle.Wrapper>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default Login;
