import styled from "styled-components";
import { Components, Icons } from "@thisgorilla/primate-ds";

export const ProfileAndNotifications = () => {
  return (
    <Container>
      <Components.IconButton
        Icon={Icons.Bell}
        disabled={false}
        kind="Secondary"
        onClick={() => {}}
        size="Medium"
      />
      <Components.IconButton
        Icon={Icons.MessageCircle}
        disabled={false}
        kind="Secondary"
        onClick={() => {}}
        size="Medium"
      />
      <Components.Avatar
        type="Circle"
        size="Small-40"
        disabled={false}
        src="/static/images/common/Gorilla.png"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 4px;
  background-color: ${({ theme }) =>
    theme.Color.elevation.surface["surface-secondary"].value};
  border-radius: 4px 50% 50% 4px;
`;
