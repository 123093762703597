import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utills/routes";
import { API_CALLS } from "../../../api_calls";

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <div>
      <button onClick={() => navigate(ROUTES.home)}>Home</button>
      <button onClick={() => navigate(ROUTES.dashboard)}>Dashboard</button>
      <button onClick={() => API_CALLS.AUTH.logout()}>Logout</button>
    </div>
  );
};

export default Dashboard;
