const home = "/";
const dashboard = "/dashboard";
const login = "/auth/login";
const register = "/auth/register";
const forgotPassword = "/auth/forgot-password";
const termsAndCondition = "/terms_and_conditions";
const privacyPolicy = "/privacy_policy";

export const ROUTES = {
  login,
  home,
  register,
  dashboard,
  termsAndCondition,
  privacyPolicy,
  forgotPassword,
};
