import { API_INSTANCES } from "../api_instance";
import store, { actions } from "../redux";

const dispatch = store.dispatch;

const getUserDetails = () => {
  API_INSTANCES.USER.getUserDetails()
    .then((response) => {
      dispatch(actions.user.setUser(response.data));
    })
    .catch((error) => {
      console.log("getUserDetails ERROR", error);
    });
};

export const USER = {
  getUserDetails,
};
