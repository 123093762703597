import { NavigateFunction } from "react-router-dom";
import {
  toastErrorMessage,
  toastSuccessMessage,
} from "@thisgorilla/primate-ds/dist/components/ToastMessage/ToastMessage";

import { API_INSTANCES } from "../api_instance";
import { ROUTES } from "../utills/routes";
import { AuthProps } from "../components/auth/Auth";
import store, { actions } from "../redux";

const dispatch = store.dispatch;

const authStatus = () => {
  API_INSTANCES.AUTH.authStatus()
    .then((response) => {
      dispatch(actions.auth.setAuthState(response?.data?.authenticated));
    })
    .catch((error) => {
      console.log("authStatus ERROR", error);
    });
};

const login = (
  formValues: AuthProps.loginFormValues,
  redirect_url: string,
  navigate: NavigateFunction
) => {
  dispatch(actions.auth.setAPICallInProgress(true));
  API_INSTANCES.AUTH.login(formValues)
    .then(() => {
      dispatch(actions.auth.setAuthState(true));
      navigate(redirect_url);
    })
    .catch((error) => {
      toastErrorMessage({
        message:
          error?.response?.data?.errors?.email?.[0] ||
          error?.response?.data?.error ||
          "something wrong when you login",
      });
      console.log("LOGIN ERROR", error);
    })
    .finally(() => dispatch(actions.auth.setAPICallInProgress(false)));
};

const logout = () => {
  API_INSTANCES.AUTH.logout()
    .then(() => {
      dispatch(actions.auth.reset());
    })
    .catch((error) => {
      console.log("logout ERROR", error);
    });
};

const register = (
  registerValue: AuthProps.RegisterValue,
  navigate: NavigateFunction
) => {
  dispatch(actions.auth.setAPICallInProgress(true));
  API_INSTANCES.AUTH.register(registerValue)
    .then(() => {
      dispatch(actions.auth.setAuthState(true));
      navigate(ROUTES.home);
    })
    .catch((error) => {
      toastErrorMessage({
        message:
          error?.response?.data?.errors?.email?.[0] ||
          error?.response?.data?.error ||
          "something wrong when you register",
      });

      console.log("Register ERROR", error);
    })
    .finally(() => dispatch(actions.auth.setAPICallInProgress(false)));
};

const forgotPassword = (email: string) => {
  dispatch(actions.auth.setAPICallInProgress(true));
  API_INSTANCES.AUTH.forgotPassword(email)
    .then(() => {
      dispatch(actions.auth.setForgotPasswordStep("otp"));
      dispatch(
        actions.auth.setResetFormValue({
          email,
          otp: "",
        })
      );
    })
    .catch((error) => {
      toastErrorMessage({
        message:
          error?.response?.data?.errors?.email?.[0] ||
          error?.response?.data?.error ||
          "something wrong when you forgot password",
      });
      console.log("forgotPassword ERROR", error);
    })
    .finally(() => dispatch(actions.auth.setAPICallInProgress(false)));
};

const resetPassword = (
  resetValue: AuthProps.ResetValue,
  navigate: NavigateFunction
) => {
  dispatch(actions.auth.setAPICallInProgress(true));
  API_INSTANCES.AUTH.resetPassword(resetValue)
    .then(() => {
      dispatch(actions.auth.setAuthState(true));
      toastSuccessMessage({
        message: "Your password has been reset. You’re logged in!",
      });
      navigate(ROUTES.home);
    })
    .catch((error) => {
      toastErrorMessage({
        message:
          error?.response?.data?.errors?.email?.[0] ||
          error?.response?.data?.error ||
          "something wrong when you reset password",
      });
      console.log("resetPassword ERROR", error);
    })
    .finally(() => dispatch(actions.auth.setAPICallInProgress(false)));
};

const validateOtp = (validateOtpValue: AuthProps.ValidateOtpValue) => {
  API_INSTANCES.AUTH.validateOtp(validateOtpValue)
    .then(() => {
      dispatch(actions.auth.setForgotPasswordStep("reset"));
      dispatch(actions.auth.setResetFormValue(validateOtpValue));
    })
    .catch((error) => {
      toastErrorMessage({
        message:
          error?.response?.data?.error || "something wrong when check otp",
      });
      console.log("validateOtp ERROR", error);
    });
};

const resendOtp = (email: string) => {
  API_INSTANCES.AUTH.resendOtp(email)
    .then(() => {
      toastSuccessMessage({
        message: "Resend Otp Successfully",
      });
    })
    .catch((error) => {
      toastErrorMessage({
        message:
          error?.response?.data ||
          error?.response?.data?.error ||
          "something wrong when resend otp",
      });
      console.log("resendOtp ERROR", error);
    });
};

export const AUTH = {
  login,
  authStatus,
  register,
  logout,
  forgotPassword,
  resetPassword,
  validateOtp,
  resendOtp,
};
