import { useEffect } from "react";
import styled from "styled-components";

import { Header } from "./Header";
import { LayoutProps } from "./Layout";
import { API_CALLS } from "../../api_calls";
import { useAppSelector } from "../../redux";

export const Layout = ({ children }: LayoutProps.IProps) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      API_CALLS.USER.getUserDetails();
    }
  }, [isAuthenticated]);
  return (
    <Container>
      <Header />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

const Container = styled.div`
  background: ${({ theme }) =>
    theme.Color.background.system["background-theme-2"].value};
`;
const ChildrenContainer = styled.div`
  padding: 32px 50px 71px 66px;
`;
