import { useState } from "react";
import { Formik, FormikValues } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { Components, tokens, Icons as DSIcons } from "@thisgorilla/primate-ds";

import Input from "../../Input";
import { AuthProps } from "../Auth";
import { Icons } from "../../icons";
import AuthLayout from "../AuthLayout";
import { AuthCommonStyle } from "../style";
import { API_CALLS } from "../../../api_calls";
import { ROUTES } from "../../../utills/routes";
import { registerValidationSchema } from "./utils";
import { API_PATHS } from "../../../utills/api_paths";
import { useAppSelector } from "../../../redux";

const Register = () => {
  const navigate = useNavigate();
  const { desktop_typography, light } = tokens;
  const [isSubmitClicked, setSubmitClicked] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const { isAPICallInProgress } = useAppSelector((state) => state.auth);
  const onSubmit = (values: FormikValues) => {
    API_CALLS.AUTH.register(values as AuthProps.RegisterValue, navigate);
  };

  return (
    <AuthLayout>
      <AuthCommonStyle.Wrapper>
        <Components.Text
          style={{
            tokenStyle: desktop_typography.Heading.L,
          }}
          text="Create an account"
          color={light.text["text-secondary"].value}
        />
        <AuthCommonStyle.Container>
          <AuthCommonStyle.SocialButton
            onClick={() =>
              window.open(`${API_PATHS.socialAuth}/google`, "_self")
            }
          >
            <Icons.Google />
            <Components.Text
              style={{
                tokenStyle: desktop_typography.Body.L,
              }}
              text="Continue with Google"
              color={light.text["text-primary"].value}
            />
          </AuthCommonStyle.SocialButton>
          <AuthCommonStyle.SocialButton
            onClick={() =>
              window.open(`${API_PATHS.socialAuth}/apple`, "_self")
            }
          >
            <Icons.Apple />
            <Components.Text
              style={{
                tokenStyle: desktop_typography.Body.L,
              }}
              text="Continue with Apple"
              color={light.text["text-primary"].value}
            />
          </AuthCommonStyle.SocialButton>
        </AuthCommonStyle.Container>
      </AuthCommonStyle.Wrapper>
      <AuthCommonStyle.InfoContainer>
        <AuthCommonStyle.Divider />
        <Components.Text
          style={{
            tokenStyle: desktop_typography.Body.M,
          }}
          text="or Sign up with Email"
          color={light.text["text-tertiary"].value}
          fontWeight="font-weight-light"
        />
        <AuthCommonStyle.Divider />
      </AuthCommonStyle.InfoContainer>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          password: "",
        }}
        validationSchema={registerValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <AuthCommonStyle.Wrapper>
              <AuthCommonStyle.Container>
                <AuthCommonStyle.GroupContainer>
                  <Input
                    fieldName="first_name"
                    idPrefix="register"
                    isRequired
                    placeholder="First Name"
                  />
                  <Input
                    fieldName="last_name"
                    idPrefix="register"
                    isRequired
                    placeholder="Last Name"
                  />
                  <Input
                    idPrefix="register"
                    isRequired
                    placeholder="Email"
                    fieldName="email"
                  />
                  <Input
                    idPrefix="register"
                    isRequired
                    placeholder="Password"
                    fieldName="password"
                    type={isPasswordShow ? "text" : "password"}
                    Icon={isPasswordShow ? DSIcons.EyeOff : DSIcons.Eye}
                    iconClickhandler={() => setIsPasswordShow(!isPasswordShow)}
                  />
                </AuthCommonStyle.GroupContainer>
                <div>
                  <Components.Text
                    style={{ tokenStyle: desktop_typography.Body.M }}
                    text="By continuing you are agreeing to our "
                    color={light.text["text-primary"].value}
                  />
                  <NavLink to={ROUTES.termsAndCondition}>
                    <Components.Text
                      style={{ tokenStyle: desktop_typography.Body.M }}
                      text="Terms & Conditions"
                      color={light.text["text-emphasize-brand"].value}
                      isunderlined
                    />
                  </NavLink>
                  <Components.Text
                    style={{ tokenStyle: desktop_typography.Body.M }}
                    text=" and "
                    color={light.text["text-primary"].value}
                  />
                  <NavLink to={ROUTES.privacyPolicy}>
                    <Components.Text
                      style={{ tokenStyle: desktop_typography.Body.M }}
                      text="Privacy Policy"
                      color={light.text["text-emphasize-brand"].value}
                      isunderlined
                    />
                  </NavLink>
                </div>
              </AuthCommonStyle.Container>
              <AuthCommonStyle.GroupContainer>
                <Components.Button
                  color="Primary"
                  kind="Primary"
                  label="Register"
                  size="Large"
                  width="fill"
                  onClick={() => {
                    setSubmitClicked(true);
                    formik.isValid && formik.submitForm();
                  }}
                  disabled={
                    (isSubmitClicked && !formik.isValid) ||
                    isAPICallInProgress ||
                    Object.keys(formik.errors).length > 0
                  }
                />

                <div>
                  <Components.Text
                    style={{ tokenStyle: desktop_typography.Body.M }}
                    text="Already have an account? "
                    color={light.text["text-primary"].value}
                  />
                  <NavLink to={ROUTES.login}>
                    <Components.Text
                      style={{ tokenStyle: desktop_typography.Body.M }}
                      text="Sign in"
                      color={light.text["text-emphasize-brand"].value}
                      isunderlined
                      fontWeight="font-weight-semibold"
                    />
                  </NavLink>
                </div>
              </AuthCommonStyle.GroupContainer>
            </AuthCommonStyle.Wrapper>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default Register;
