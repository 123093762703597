import { createSlice } from "@reduxjs/toolkit";
import { AuthSliceProps } from "./auth";

const initialState: AuthSliceProps.State = {
  isAuthenticated: false,
  isAuthenticatedApiCall: false,
  isAPICallInProgress: false,
  forgotPasswordStep: "forgot",
  resetFormValue: { email: "", otp: "" },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action: AuthSliceProps.setAuthState) => {
      state.isAuthenticated = action.payload;
      state.isAuthenticatedApiCall = true;
    },
    setAPICallInProgress: (
      state,
      action: AuthSliceProps.setAPICallInProgress
    ) => {
      state.isAPICallInProgress = action.payload;
    },
    setForgotPasswordStep: (
      state,
      action: AuthSliceProps.setForgotPasswordStep
    ) => {
      state.forgotPasswordStep = action.payload;
    },
    setResetFormValue: (state, action: AuthSliceProps.setResetFormValue) => {
      state.resetFormValue = {
        email: action.payload.email || state.resetFormValue.email,
        otp: action.payload.otp || state.resetFormValue.otp,
      };
    },
    reset: () => initialState,
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
