import * as Yup from "yup";
import { invalidDomains } from "./inValidDomains";

const checkIfBusinessEmailInvalid = (email: string) => {
  const domain = email.split("@")[1];
  return invalidDomains[domain] ? true : false;
};

export const registerValidationSchema = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Please Enter valid email")
    .test(
      "not-valid",
      "Please enter a valid business email address.",
      (value) => {
        return !checkIfBusinessEmailInvalid(value as string);
      }
    )
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});
