import { css } from "styled-components";

const defaultContainer = css`
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-m"]};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
`;
const defaultPanelContainer = css`
  ${defaultContainer};
  width: 316px;
  padding: 24px 16px;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;

export const HomeStyles = { defaultContainer, defaultPanelContainer };
