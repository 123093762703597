import { createSlice } from "@reduxjs/toolkit";

import { UserProps } from "./user";

const DefaultUser: UserProps.User = {
  first_name: "",
  headline: "",
  id: "",
  last_name: "",
  profile_picture: "",
};
const initialState: UserProps.State = {
  user: DefaultUser,
  allUsers: [],
  innerCircle: [],
  mentions: [],
  peopleUserMightKnow: [],
  suggestedUsers: [],

  recommendedJobs: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: UserProps.setUser) => {
      state.user = action.payload;
    },
    setPeopleUserMightKnow: (
      state,
      action: UserProps.setPeopleUserMightKnow
    ) => {
      state.peopleUserMightKnow = action.payload;
    },
    setInnerCircle: (state, action: UserProps.setInnerCircle) => {
      state.innerCircle = action.payload;
    },
    setMentions: (state, action: UserProps.setMentions) => {
      state.mentions = action.payload;
    },
    setSuggestedUsers: (state, action: UserProps.setSuggestedUsers) => {
      state.suggestedUsers = action.payload;
    },
    setAllUsers: (state, action: UserProps.setAllUsers) => {
      state.allUsers = action.payload;
    },

    setRecommendedJobs: (state, action: UserProps.setRecommendedJobs) => {
      state.recommendedJobs = action.payload;
    },

    reset: () => initialState,
  },
});

export const userActions = slice.actions;
export const userReducer = slice.reducer;
