import styled from "styled-components";
import { HomeStyles } from "../../home.styled";

const Container = styled.div`
  gap: 16px;
  padding: 20px;
  ${HomeStyles.defaultContainer}
`;
const AvatarAndText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const PostAsAndAttachments = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Attachments = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;
`;

export const DefaultPostStyles = {
  Container,
  AvatarAndText,
  PostAsAndAttachments,
  Attachments,
};
