import * as Yup from "yup";

export const forgotValidationSchema = Yup.object({
  email: Yup.string().required("Email is required").email("Invalid email"),
});

export const resetValidationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password")],
      "Confirm password does not match the new password"
    )
    .required("Please confirm your password"),
});

export const checkPasswordStrength = (password: string) => {
  const weakPassword = /^(?:.{0,7}|[a-z]+$|\d+$|(.)(?:\1+)$)/;
  const averagePassword =
    /^(?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[!@#$%^&*])|(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*[!@#$%^&*])|(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
  const strongPassword =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*password)(?!.*123)(?!.*qwerty)(?=.{8,})/;

  if (strongPassword.test(password)) {
    return "strong";
  }
  if (averagePassword.test(password)) {
    return "average";
  }
  if (weakPassword.test(password)) {
    return "weak";
  }

  return "weak";
};
