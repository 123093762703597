import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./utills/routes";
import { Pages } from "./components/pages";
import { Auth } from "./components/auth";
import { Protected } from "./components/Protected";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.login} element={<Auth.Login />} />
        <Route path={ROUTES.register} element={<Auth.Register />} />
        <Route path={ROUTES.forgotPassword} element={<Auth.ForgotPassword />} />
        <Route
          path={ROUTES.home}
          element={<Protected component={<Pages.Home />} />}
        />
        <Route
          path={ROUTES.dashboard}
          element={<Protected component={<Pages.Dashboard />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
