import { Components, Icons, Inputs } from "@thisgorilla/primate-ds";

import { DefaultPostStyles } from "./DefaultPost.styled";

export const DefaultPost = () => {
  const idPrefix = "default-post";

  return (
    <DefaultPostStyles.Container>
      <DefaultPostStyles.AvatarAndText>
        <Components.Avatar
          type="Circle"
          size="XSmall-28"
          src="/static/images/common/Gorilla.png"
          disabled={false}
        />
        <div style={{ width: "100%" }}>
          <Inputs.Input
            idPrefix={idPrefix}
            fieldName="post"
            isRequired={false}
            onChangeHandler={() => {}}
            value=""
            placeholder="Start posting"
            size="Small"
          />
        </div>
      </DefaultPostStyles.AvatarAndText>

      <DefaultPostStyles.PostAsAndAttachments>
        <DefaultPostStyles.Attachments className="default-post-attachments">
          <Components.Tooltip label="Add media">
            <Components.IconButton
              Icon={Icons.Image}
              kind="Secondary"
              size="Medium"
              onClick={() => {}}
              disabled={false}
            />
          </Components.Tooltip>

          <Components.Tooltip label="Add a GIF">
            <Components.IconButton
              Icon={Icons.Gif}
              kind="Secondary"
              size="Medium"
              onClick={() => {}}
              disabled={false}
            />
          </Components.Tooltip>

          <Components.Tooltip label="Create a poll">
            <Components.IconButton
              Icon={Icons.Poll}
              kind="Secondary"
              size="Medium"
              onClick={() => {}}
              disabled={false}
            />
          </Components.Tooltip>

          <Components.Tooltip label="Create a slider">
            <Components.IconButton
              Icon={Icons.Slider}
              kind="Secondary"
              size="Medium"
              onClick={() => {}}
              disabled={false}
            />
          </Components.Tooltip>
        </DefaultPostStyles.Attachments>
      </DefaultPostStyles.PostAsAndAttachments>
    </DefaultPostStyles.Container>
  );
};
