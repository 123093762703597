import { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ROUTES } from "../utills/routes";
import { useAppSelector } from "../redux";
import { API_CALLS } from "../api_calls";
import {Layout} from "./layout";

export const Protected = ({ component }: { component: JSX.Element }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, isAuthenticatedApiCall } = useAppSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (!isAuthenticatedApiCall) {
      API_CALLS.AUTH.authStatus();
    }
  }, [isAuthenticatedApiCall]);

  useEffect(() => {
    if (!isAuthenticatedApiCall) return;
    if (!isAuthenticated) {
      navigate(`${ROUTES.login}?redirectURL=${location.pathname}`);
    }
  }, [isAuthenticated, isAuthenticatedApiCall, location.pathname, navigate]);

  return !isAuthenticatedApiCall ? (
    <div>loading.....</div>
  ) : isAuthenticated ? (
    <Layout>{component}</Layout>
  ) : (
    <Fragment />
  );
};
