const baseUrl = process.env.REACT_APP_GORRILA_API;

const login = baseUrl + "/auth";
const socialAuth = baseUrl + "/auth";
const register = baseUrl + "/auth/register";
const forgotPassword = baseUrl + "/auth/forgot-password";
const resetPassword = baseUrl + "/auth/reset-password";
const validateOtp = baseUrl + "/auth/check-otp";
const resendOtp = baseUrl + "/auth/resend-otp";
const authStatus = baseUrl + "/auth/status";
const userDetails = baseUrl + "/me";

export const API_PATHS = {
  login,
  authStatus,
  socialAuth,
  register,
  userDetails,
  forgotPassword,
  resetPassword,
  validateOtp,
  resendOtp,
};
