import { authActions, authReducer } from "./auth";
import { userActions, userReducer } from "./user";

export type ReduxKeys = keyof typeof actions;

export const actions = {
  auth: authActions,
  user: userActions,
};

export const reducer = {
  auth: authReducer,
  user: userReducer,
};
