import instance from "../axios/axios-user";
import { AuthProps } from "../components/auth/Auth";
import { API_PATHS } from "../utills/api_paths";

const login = (body: AuthProps.loginFormValues) => {
  const config = {
    method: "POST",
    url: API_PATHS.login,
    data: body,
  };
  return instance(config);
};

const logout = () => {
  const config = {
    method: "DELETE",
    url: API_PATHS.login,
  };
  return instance(config);
};

const register = (registerValue: AuthProps.RegisterValue) => {
  const config = {
    method: "POST",
    url: API_PATHS.register,
    data: registerValue,
  };
  return instance(config);
};

const authStatus = () => instance(API_PATHS.authStatus);

const forgotPassword = (email: string) => {
  const config = {
    method: "POST",
    url: API_PATHS.forgotPassword,
    data: { email },
  };
  return instance(config);
};

const resetPassword = (resetValue: AuthProps.ResetValue) => {
  const config = {
    method: "POST",
    url: API_PATHS.resetPassword,
    data: resetValue,
  };
  return instance(config);
};

const validateOtp = (validateOtpValue: AuthProps.ValidateOtpValue) => {
  const config = {
    method: "POST",
    url: API_PATHS.validateOtp,
    data: validateOtpValue,
  };
  return instance(config);
};

const resendOtp = (email: string) => {
  const config = {
    method: "POST",
    url: API_PATHS.resendOtp,
    data: { email },
  };
  return instance(config);
};

export const AUTH = {
  login,
  authStatus,
  register,
  logout,
  forgotPassword,
  resetPassword,
  validateOtp,
  resendOtp,
};
