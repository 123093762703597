import { KeyboardEvent, useState } from "react";
import { Field, FieldProps } from "formik";
import { Input as DSInput } from "@thisgorilla/primate-ds/dist/inputs/Input/Input";
import { CommonFieldProps } from "@thisgorilla/primate-ds/dist/inputs/CommonField";
import { Components, tokens } from "@thisgorilla/primate-ds";
import { IconsProps } from "@thisgorilla/primate-ds/dist/Icons";
import styled from "styled-components";
import { checkPasswordStrength } from "../auth/forgot_password/utils";

const Input = (
  props: CommonFieldProps.Iprops & {
    type?: "text" | "password";
    Icon?: IconsProps.Icon;
    iconClickhandler?: () => void;
    showPasswordStrength?: boolean;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  }
) => {
  const {
    fieldName,
    helpText,
    helpTextKind,
    onKeyDown = () => {},
    showPasswordStrength = false,
  } = props;
  const [isFocused, setFocused] = useState(false);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta, form }: FieldProps) => {
        const { value } = field;
        const { error } = meta;
        const { setFieldValue, validateField } = form;
        return (
          <>
            <DSInput
              {...{
                ...props,
                value,
                helpText: !isFocused && error ? error : helpText,
                helpTextKind: !isFocused && error ? "Error" : helpTextKind,
                onChangeHandler: (e) => {
                  setFieldValue(fieldName, e.target.value, false).then(() => {
                    validateField(fieldName);
                  });
                },
              }}
              onKeyDown={onKeyDown}
              onFocus={() => setFocused(true)}
              onBlur={() => {
                setFocused(false);
                validateField(fieldName);
              }}
            />
            {showPasswordStrength && isFocused && (
              <Container>
                <div>
                  <Components.LinearProgress
                    type="Multi"
                    size="Small"
                    percentage={{ Negative: 20, Positive: 0, Primary: 0 }}
                  />
                </div>
                <Components.Text
                  text={checkPasswordStrength(value)}
                  style={{ tokenStyle: tokens.desktop_typography.Body.M }}
                  color={
                    checkPasswordStrength(value) === "weak"
                      ? tokens.light.text["text-emphasize-negative"].value
                      : checkPasswordStrength(value) === "average"
                      ? tokens.light.text["text-emphasize-warm"].value
                      : tokens.light.text["text-emphasize-positive"].value
                  }
                />
              </Container>
            )}
          </>
        );
      }}
    </Field>
  );
};

export default Input;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 37.5px;
  & > div {
    width: 100%;
  }
  & > label {
    text-transform: capitalize;
  }
`;
