import { useState } from "react";
import { Formik, FormikValues } from "formik";
import { Components, tokens, Icons } from "@thisgorilla/primate-ds";

import Input from "../../../Input";
import { AuthCommonStyle } from "../../style";
import { resetValidationSchema } from "../utils";
import { useAppSelector } from "../../../../redux";
import { API_CALLS } from "../../../../api_calls";
import { useNavigate } from "react-router-dom";

const Reset = () => {
  const navigate = useNavigate();
  const { light, desktop_typography } = tokens;
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);
  const { isAPICallInProgress, resetFormValue } = useAppSelector(
    (state) => state.auth
  );

  const onSubmit = (values: FormikValues) => {
    API_CALLS.AUTH.resetPassword(
      {
        email: resetFormValue.email,
        otp: resetFormValue.otp,
        new_password: values.password,
      },
      navigate
    );
  };

  return (
    <AuthCommonStyle.Wrapper>
      <AuthCommonStyle.GroupContainer>
        <center>
          <Components.Text
            style={{
              tokenStyle: desktop_typography.Heading.L,
            }}
            text="Reset your password"
            color={light.text["text-secondary"].value}
          />
        </center>
        <center>
          <Components.Text
            style={{
              tokenStyle: desktop_typography.Body.M,
            }}
            text="Please create a new password to log in"
            color={light.text["text-emphasize-brand"].value}
          />
        </center>
      </AuthCommonStyle.GroupContainer>

      <Formik
        initialValues={{ password: "", confirm_password: "" }}
        onSubmit={onSubmit}
        validationSchema={resetValidationSchema}
      >
        {(formik) => (
            <AuthCommonStyle.Container>
              <Input
                fieldName="password"
                placeholder="New Password"
                idPrefix="reset"
                isRequired
                showPasswordStrength
                type={isPasswordShow ? "text" : "password"}
                Icon={isPasswordShow ? Icons.EyeOff : Icons.Eye}
                iconClickhandler={() => setIsPasswordShow(!isPasswordShow)}
              />
              <Input
                placeholder="Confirm Password"
                idPrefix="reset"
                isRequired
                fieldName="confirm_password"
                type={isConfirmPasswordShow ? "text" : "password"}
                Icon={isConfirmPasswordShow ? Icons.EyeOff : Icons.Eye}
                iconClickhandler={() =>
                  setIsConfirmPasswordShow(!isConfirmPasswordShow)
                }
              />
              <Components.Button
                color="Primary"
                kind="Primary"
                label="Reset password"
                size="Large"
                width="fill"
                onClick={() => {
                  setSubmitClicked(true);
                  formik.isValid && formik.submitForm();
                }}
                disabled={
                  (submitClicked && !formik.isValid) ||
                  isAPICallInProgress ||
                  Object.keys(formik.errors).length > 0
                }
              />
            </AuthCommonStyle.Container>
        )}
      </Formik>
    </AuthCommonStyle.Wrapper>
  );
};

export default Reset;
